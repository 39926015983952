










































































import Vue from "vue";
import rootStore from "@/store";
import { DementiaState } from "@/types/dementia";
const state = rootStore.state as { dementia: DementiaState };

export default Vue.extend({
  name: "GenomeApply",
  props: ["userStatus"],
  data: function() {
    return {
      isLoading: false
    };
  },
  methods: {
    requestGenome() {
      rootStore.commit("dementia/changeIsRequestGenome", { value: true });
    },
    postGenomeRequest() {
      this.isLoading = true;
      Promise.all([
        this.axios.post(
          process.env.VUE_APP_API_BASE_URL +
            "v2/api/self_checker/action_reservation",
          { geneInspection: true },
          { withCredentials: true }
        )
      ])
        .then(_r => {
          rootStore.commit("dementia/changeIsPostedGenomeApply", {
            value: true
          });
          this.isLoading = false;
        })
        .catch(error => {
          //もし接続の問題とかで取得が失敗した場合でもfallbackとしてisLoading=false/userStatus=not_login
          console.error(error);
          this.isLoading = false;
        });
    }
  },
  computed: {
    isPosted() {
      return state.dementia.isPostedGenomeApply;
    },
    fetchRedirectUrl(): string {
      return encodeURIComponent(
        process.env.VUE_APP_BASE_URL + "result" + location.search
      );
    },
    fetchFreeRegisterUrl(): string {
      return (
        process.env.VUE_APP_API_BASE_URL +
        "user/pre-register/free?return_to=" +
        this.fetchRedirectUrl
      );
    },
    fetchLoginUrl(): string {
      return (
        process.env.VUE_APP_API_BASE_URL +
        "login?return_to=" +
        this.fetchRedirectUrl
      );
    }
  }
});
