





















import Vue from "vue";

export default Vue.extend({
  name: "Feedback",
  props: ["patientId"],
  data: function() {
    return {};
  },
  methods: {},
  computed: {}
});
