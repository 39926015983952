





























































import Vue from "vue";
import { topics, advices } from "../../data/dementia";
import rootStore from "@/store";
import { DementiaState } from "@/types/dementia";
const state = rootStore.state as { dementia: DementiaState };

export default Vue.extend({
  name: "RecommendTopics",
  props: [""],
  data: function() {
    return {};
  },
  methods: {},
  computed: {
    fetchTopics() {
      const allTopicsFitIds = state.dementia.riskAnswers
        .map((answer, idx) => {
          const advicesFit = advices().filter(
            ad => ad.id === idx && ad.conditions.ans === answer
          );
          const topicsFitIds = advicesFit.map(ad => ad.topicIds);
          return topicsFitIds;
        })
        .flat(Infinity)
        .filter((el): el is number => typeof el == "number");
      // uniqしてからtopicsを返す
      const topicsFit = Array.from(new Set(allTopicsFitIds)).map(topicId =>
        topics().find(t => t.id === topicId)
      );
      // 一つも推薦すべきトピックがなければデフォのトピック一個を返す
      if (topicsFit.length === 0) {
        return [topics().find(t => t.id === 1)];
      }
      return topicsFit.length < 4 ? topicsFit : topicsFit.slice(0, 3);
    }
  }
});
