<template>
  <div class="result">
    <img
      alt="checker logo"
      src="../../assets/dementia/logo_4.svg"
      class="chapter-logo"
    />
    <div class="title-box">
      <p class="heading-text">認知症の発症リスク結果</p>
    </div>
    <RiskResults :riskAnswers="riskAnswers" />
    <div class="title-box">
      <p class="heading-text">受診目安チェックの結果</p>
    </div>
    <div class="white-box">
      <div style="margin: 20px;">
        <p class="bold-text">受診の目安</p>
        <span class="score-text">{{ this.score }}</span>
        <span class="bold-text">点</span>
        <div style="width: 100%">
          <span style="float: left;">リスク：低</span>
          <span style="float: right;">リスク：高</span>
        </div>
        <v-progress-linear
          height="24"
          class="progress-linear"
          v-bind:class="resultSummary.className"
          v-bind:value="(this.score / 20) * 100"
          background-color="#F3F3F3"
          v-bind:color="resultSummary.barColor"
          max="20"
        ></v-progress-linear>
        <div style="width: 100%">
          <span style="float: left;">0点</span>
          <span style="float: right;">20点</span>
        </div>
        <br /><br />

        <div class="result-box" v-bind:class="resultSummary.className">
          <p class="bold-text result-text">{{ resultSummary.text }}</p>
        </div>
      </div>
    </div>
    <div v-if="isLoading">
      <v-progress-circular
        :size="70"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <div v-else>
      <GenomeApply :userStatus="userStatus" />
    </div>
    <RecommendTopics />
    <Feedback :patientId="patientId" />

    <div class="note-box">
      <p class="bold-text">受診の目安チェックについて</p>
      <ul class="caution-list notice-text">
        <li>
          本テストは認知症のごく初期、認知症の始まり、あるいは認知症に進展する可能性のある状態を、老年者（６０歳以上）自身が、あるいは配偶者または同居者などが簡単に予測できるように考案されたものです。
        </li>
        <li>
          本テストの結果はあくまでもおおよその目安で医学的診断に代わるものではありません。
          認知症の診断には医療機関での受診が必要です。
        </li>
      </ul>
      <div class="notice-text" style="text-align:left;">
        <p class="text-block">
          出典：大友式認知症予測テスト（公益財団法人認知症予防財団HPより）
        </p>
        <p class="bold-text">認知症の発症リスクチェックについて</p>
        <p>参考文献：</p>
        <br />
        <div class="note-block notice-text">
          <p>
            Dementia risk estimates associated with measures of depression: a
            systematic review and meta-analysis<br /><br />
            https://pubmed.ncbi.nlm.nih.gov/26692556/
          </p>
          <p>
            Association Between Daily Sleep Duration and Risk of Dementia and
            Mortality in a Japanese Community<br /><br />
            https://pubmed.ncbi.nlm.nih.gov/29873398/
          </p>

          <p>
            Midlife and Late-Life Smoking and Risk of Dementia in the Community:
            The Hisayama Study<br /><br />
            https://pubmed.ncbi.nlm.nih.gov/26503243/
          </p>

          <p>
            Dietary patterns and risk of dementia in an elderly Japanese
            population: the Hisayama Study<br /><br />
            https://pubmed.ncbi.nlm.nih.gov/23553168/
          </p>

          <p>
            Glucose tolerance status and risk of dementia in the community: the
            Hisayama study<br /><br />
            https://pubmed.ncbi.nlm.nih.gov/21931106/
          </p>

          <p>
            Fish consumption and risk of incident dementia in elderly Japanese:
            the Ohsaki cohort 2006 study<br /><br />
            https://pubmed.ncbi.nlm.nih.gov/31477191/
          </p>

          <p>
            Green Tea Consumption and the Risk of Incident Dementia in Elderly
            Japanese: The Ohsaki Cohort 2006 Study<br /><br />
            https://pubmed.ncbi.nlm.nih.gov/27594507/
          </p>

          <p>
            Association between Coffee Consumption and Incident Risk of
            Disabling Dementia in Elderly Japanese: The Ohsaki Cohort 2006
            Study<br /><br />
            https://pubmed.ncbi.nlm.nih.gov/26682686/
          </p>

          <p>
            Alcohol consumption and incident dementia in older Japanese adults:
            The Okayama Study<br /><br />
            https://pubmed.ncbi.nlm.nih.gov/31173440/
          </p>
          <p>
            Impact of time spent walking on incident dementia in elderly
            Japanese<br /><br />
            https://pubmed.ncbi.nlm.nih.gov/30350352/
          </p>
          <p>
            Lifestyle activities and the risk of dementia in older Japanese
            adults<br /><br />
            https://pubmed.ncbi.nlm.nih.gov/30133092/
          </p>

          <p>
            An additive effect of leading role in the organization between
            social participation and dementia onset among Japanese older adults:
            the AGES cohort study<br /><br />
            https://pubmed.ncbi.nlm.nih.gov/29284406/
          </p>

          <p>
            Citrus consumption and incident dementia in elderly Japanese: the
            Ohsaki Cohort 2006 Study<br /><br />
            https://pubmed.ncbi.nlm.nih.gov/28521847/
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GenomeApply from "@/components/dementia/GenomeApply.vue";
import Feedback from "@/components/dementia/Feedback.vue";
import RiskResults from "@/components/dementia/RiskResults.vue";
import RecommendTopics from "@/components/dementia/RecommendTopics.vue";
import rootStore from "@/store";
export default {
  name: "Result",
  head: {
    title: {
      inner: "認知症かんたんチェック"
    }
  },
  components: {
    GenomeApply,
    Feedback,
    RiskResults,
    RecommendTopics
  },
  data: function() {
    return {
      isLoading: true,
      riskAnswers: rootStore.state.dementia.riskAnswers
    };
  },
  methods: {},
  beforeCreate: function() {
    this.isLoading = true;
    this.axios
      .get(process.env.VUE_APP_API_BASE_URL + "v2/api/self_checker/user_info", {
        withCredentials: true
      })
      .then(res => {
        this.$cookies.set("patient_id", res.data.patientId, "24h");
        rootStore.commit("dementia/changeUserInfo", {
          patientId: res.data.patientId,
          userStatus: res.data.userStatus
        });
        this.isLoading = false;
        if (
          res.data.userStatus !== "not_login" &&
          !rootStore.state.dementia.isPostedCheckResult
        )
          this.axios
            .post(
              process.env.VUE_APP_API_BASE_URL + "v2/api/self_checker/scores",
              {
                score: rootStore.state.dementia.score,
                version: "0.4",
                age: rootStore.state.dementia.age,
                gender: rootStore.state.dementia.gender,
                riskAnswer: JSON.stringify({
                  riskAnswers: rootStore.state.dementia.riskAnswers,
                  screeningAnswers: rootStore.state.dementia.screeningAnswers
                })
              },
              { withCredentials: true }
            )
            .then(_r =>
              rootStore.commit("dementia/changeIsPostedCheckResult", {
                value: true
              })
            );

        if (rootStore.state.dementia.isRequestRemind) {
          this.axios
            .post(
              process.env.VUE_APP_API_BASE_URL +
                "v2/api/self_checker/action_reservation",
              { selfCheckRemind: true },
              { withCredentials: true }
            )
            .then(_r =>
              rootStore.commit("dementia/changeIsRequestRemind", {
                value: false
              })
            );
        }

        if (rootStore.state.dementia.isRequestGenome) {
          this.axios
            .post(
              process.env.VUE_APP_API_BASE_URL +
                "v2/api/self_checker/action_reservation",
              { geneInspection: true },
              { withCredentials: true }
            )
            .then(_r => {
              rootStore.commit("dementia/changeIsRequestGenome", {
                value: false
              });
              rootStore.commit("dementia/changeIsPostedGenomeApply", {
                value: true
              });
            });
        }
      })
      .catch(error => {
        console.log(error);
        rootStore.commit("dementia/changeUserInfo", {
          patientId: "",
          userStatus: "not_login"
        });
        this.isLoading = false;
      });
  },
  computed: {
    score() {
      return typeof rootStore.state.dementia.score === "number"
        ? rootStore.state.dementia.score
        : 0;
    },
    userStatus() {
      return rootStore.state.dementia.userStatus;
    },
    patientId() {
      return rootStore.state.dementia.patientId;
    },
    resultSummary() {
      const score = Number(rootStore.state.dementia.score);
      if (score >= 14) {
        return {
          text: "専門医などで診断を",
          className: "danger-result",
          barColor: "#FF0F00"
        };
      } else if (score >= 9) {
        return {
          text: "要注意",
          className: "warning-result",
          barColor: "#FF9900"
        };
      } else {
        return {
          text: "正常",
          className: "normal-result",
          barColor: "#07D61C"
        };
      }
    }
  }
};
</script>
<style scoped>
@import "../../assets/style/dementia.css";
.score-text {
  font-style: normal;
  font-weight: bold;
  font-size: 96px;
  line-height: 112px;
}

div.danger-result {
  background: #ffebeb;
  color: #de0000;
}

div.warning-result {
  color: #fe5b00;
  background: #fff1db;
}

div.normal-result {
  color: #08bd1a;
  background: #e4f9e6;
}

.progress-linear {
  margin-top: 30px;
  margin-bottom: 10px;
}

.result-box {
  margin: auto;
  height: 68px;
}

.result-text {
  line-height: 68px;
}
.note-block > p {
  margin-bottom: 50px;
}
</style>
