












import Vue from "vue";
import rootStore from "@/store";
import { questions, advices } from "../../data/dementia";
export default Vue.extend({
  name: "RiskResults",
  props: ["riskAnswers"],
  data: function() {
    return {
      questions: questions(),
      advices: advices()
    };
  },
  methods: {
    fetchAdvice(questionId: number, answerId: number): string {
      const advices = this.advices.filter(
        ad => ad.id === questionId && ad.conditions.ans === answerId
      );
      if (advices.length === 1) {
        const advice = advices[0];
        const conditionsResult =
          (advice.conditions.ages === undefined ||
            advice.conditions.ages.includes(
              rootStore.getters["dementia/numberAge"]
            )) &&
          (advice.conditions.gender === undefined ||
            advice.conditions.gender ===
              rootStore.getters["dementia/numberGender"]);
        return conditionsResult ? advice.positive : advice.negative;
      } else {
        //ポジティブな回答が存在すればそれを返す、なければ何かしらのnegativeを返す
        const advicesPositive = advices.filter(
          ad =>
            (ad.conditions.ages === undefined ||
              ad.conditions.ages.includes(
                rootStore.getters["dementia/numberAge"]
              )) &&
            (ad.conditions.gender === undefined ||
              ad.conditions.gender ===
                rootStore.getters["dementia/numberGender"])
        );
        return advicesPositive.length > 0
          ? advicesPositive[0].positive
          : advices[0].negative;
      }
    }
  },
  computed: {}
});
